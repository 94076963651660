import './style.css';
import ValentinesDay from './ValentinesDay'; // Importar el componente ValentinesDay

function App() {
  return (
    <div className="App">
      <ValentinesDay /> {/* Renderizar el componente ValentinesDay */}
    </div>
  );
}

export default App;

